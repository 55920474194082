import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
// components
import Pagination from 'src/components/Pagination';
// section
import Section from 'src/components/Section';
import WebsitePage from 'src/components/WebsitePage';
// hooks
import useWindowSize from 'src/hooks/useWindowSize';

import * as style from './Pages.module.scss';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { PaginationVariant } from 'src/components/Pagination/Pagination.interface';

type Props = {
  title: string;
  isHeader: boolean;
  pages: {
    image: IGatsbyImageData;
  }[];
  variantPagination: PaginationVariant;
};

const Pages: FC<Props> = ({ title, pages, isHeader, variantPagination }) => {
  const [isRenderedOnClient, setIsRenderedOnClient] = useState(false);
  const { isMobile, isTablet, isLaptop } = useWindowSize();
  // state
  const [activeSlide, setActiveSlide] = useState<number>(0);
  // refs
  const sectionRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);
  // memo
  const sliderSettings = useMemo<Settings>(
    () => ({
      centerMode: false,
      dots: false,
      arrows: false,
      accessibility: false,
      infinite: false,
      variableWidth: true,
      beforeChange: (_: number, nextSlide: number) => {
        setActiveSlide(nextSlide);
      },
    }),
    []
  );
  const slideWidth = useMemo<number>(() => {
    const defaultPaddings = 240;
    const laptopPaddings = 120;
    const tabletPaddings = 0;
    const mobilePaddings = 0;

    let sectionPadding = defaultPaddings;

    if (isLaptop) {
      sectionPadding = laptopPaddings;
    } else if (isTablet) {
      sectionPadding = tabletPaddings;
    } else if (isMobile) {
      sectionPadding = mobilePaddings;
    }

    if (typeof document === 'undefined') {
      return defaultPaddings;
    }

    return document.body.clientWidth - sectionPadding;
  }, [isMobile, isTablet, isLaptop]);
  const slideRatio = useMemo<number>(() => {
    const defaultRatio = 52.88;
    const mobileRatio = 60;

    if (isMobile) {
      return mobileRatio;
    }

    return defaultRatio;
  }, [isMobile]);

  useEffect(() => {
    setIsRenderedOnClient(true);
  }, []);

  const handlePageClick = useCallback((slideIndex: number) => {
    sliderRef.current?.slickGoTo(slideIndex);
  }, []);

  if (!pages || pages.length === 0) {
    return null;
  }

  return (
    <Section title={title} className={style.section} ref={sectionRef}>
      {isRenderedOnClient && (
        <Slider className={style.slider} ref={sliderRef} {...sliderSettings}>
          {pages.map((page, i) => (
            <div className={style.slide} key={i} style={{ width: slideWidth }}>
              <WebsitePage
                isHeader={isHeader}
                image={page.image}
                ratioSize={slideRatio}
              />
            </div>
          ))}
        </Slider>
      )}
      <div className={style.pagination}>
        <Pagination
          variant={variantPagination}
          itemsCount={pages.length}
          activeItem={activeSlide}
          onClick={handlePageClick}
        />
      </div>
    </Section>
  );
};

export default Pages;
