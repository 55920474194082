// components
import Seo from 'src/components/Seo';
// types
import type { PageProps } from 'gatsby';
import type { FC } from 'react';
import type { PortfolioProject } from 'src/cms/data/home/usePortfolioData';

export const Head: FC<PageProps<{}, { project: PortfolioProject }>> = ({
  location,
  pageContext: { project },
}) => {
  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={project?.metaTitle}
      description={project?.metaDescription}
      schema={{
        schemaContext: project?.schema?.schemaContext ?? 'https://schema.org',
        schemaType: project?.schema?.schemaType ?? 'Article',
        schemaName: project?.schema?.schemaName ?? project?.metaTitle,
        schemaDescription:
          project?.schema?.schemaDescription ?? project?.metaDescription,
        schemaUrl:
          project?.schema?.schemaUrl ??
          `https://seedium.io${location.pathname}`,
        schemaLogo:
          project?.schema?.schemaLogo ?? 'https://seedium.io/images/logo.png',
      }}
    />
  );
};

export { default } from './Project';
